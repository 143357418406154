

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/droit-recours-salarie-environnement-travail-avocat.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost44 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les droits et recours du salarié dans son environnement de travail - Soumissions Avocat"
        description="Subir un préjudice au travail, qu’il soit physique, matériel ou moral, laisse des traces indélébiles sur les victimes. Quelle que soit la nature du préjudice subi, lorsqu’il résulte de la faute d’un employeur, il y a lieu d’être compensé pour un tel manquement de diligence. Ce principe est d’ailleurs reconnu LIRE PLUS"
        image={LeadImage}>
        <h1>Les droits et recours du salarié dans son environnement de travail</h1>

					
					
						<p>Subir un préjudice au travail, qu’il soit physique, matériel ou moral, laisse des traces indélébiles sur les victimes. Quelle que soit la nature du préjudice subi, lorsqu’il résulte de la faute d’un employeur, il y a lieu d’être compensé pour un tel manquement de diligence. Ce principe est d’ailleurs reconnu par le droit québécois et appuyé par diverses lois du travail, peu importe le milieu dans lequel vous œuvrez.</p>
<p><StaticImage alt="droit-recours-salarie-environnement-travail-avocat" src="../images/droit-recours-salarie-environnement-travail-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Un tel recours vise à punir les employeurs en torts pour dissuader une telle conduite, mais également à indemniser les salariés victimes d’un abus. Cette forme de justice pourrait bien s’appliquer à votre situation si vous avez subi un dommage quelconque! Vérifiez donc ce qu’un avocat peut faire pour vous aider à gagner votre recours contre votre employeur ou pour entreprendre un recours auprès de la CNESST!</p>
<h2>Les droits et obligations dans la relation employeur/employé</h2>
<p>S’il y a bien une chose que presque tous les citoyens de la province ont en commun, c’est le fait de se lever chaque matin pour se rendre au travail. Cela est inévitable dans notre système, ce qui implique des millions de relations employeurs/employés que la loi se doit d’encadrer. Le passé étant rempli d’abus de part et d’autre, la législation d’aujourd’hui prévoit de nombreux droits et obligations d’un côté comme de l’autre de cette relation. Voici donc ce que l’employeur et son salarié se doivent mutuellement.</p>
<p><strong>Le salarié :</strong></p>
<p><strong>Loyauté :</strong> Qu’un contrat ou une clause de non-concurrence aient été signés n’a pas la moindre importance. La loi prévoit explicitement que l’employé a un devoir de loyauté envers son employeur exigeant qu’il se conduise de façon à ne pas miner les intérêts de ce dernier. Cette obligation existe même avant le début de la prestation de travail. Faites donc attention aux messages douteux que vous pourriez être tentés d’écrire sur internet!</p>
<p><strong>Obéir aux consignes :</strong> Cette obligation peut sembler difficile à croire pour certains salariés, mais dans un contrat de travail (écrit ou verbal), le salarié s’engage à effectuer ses tâches sous la gouverne de son employeur. C’est ce que le droit qualifie de lien de subordination et il contraint donc les employés à obéir aux ordres qui leur sont donnés dans les limites de la sécurité, la dignité et la légalité.</p>
<p><strong>Prudence et diligence :</strong> Quelle que soit la nature du travail effectué, qu’il se passe sur un chantier de construction ou un bureau de comptables, la prestation offerte par un salarié se doit d’être faite dans la prudence et la diligence. Cela implique de prendre toutes les précautions habituellement prises par des salariés raisonnables placés dans une situation similaire.</p>
<p><strong>Exécuter le travail :</strong> Cela peut sembler ridicule comme obligation, mais le principe qui ressort de ce droit est le simple fait que le contrat de travail en est un qui est personnel. C’est vous personnellement que l’employeur a engagé, alors vous ne pouvez pas laisser une autre personne effectuer le travail pour vous.</p>
<p><strong>
L’employeur :</strong></p>
<p><strong>Milieu de travail sécuritaire : </strong>Bien que l’employé doive lui-même prendre les précautions nécessaires à l’exécution sécuritaire de son travail, l’employeur a lui aussi l’obligation de fournir un milieu de travail sécuritaire. Ce dualisme au niveau des devoirs de précaution vise à minimiser les accidents de travail.</p>
<p><strong>Payer le salaire convenu :</strong> L’employé se doit d’exécuter sa prestation en prenant soin de suivre les consignes qui lui sont données, alors l’employeur est dans l’obligation de payer la somme convenue en guise de salaire.</p>
<p><strong>Respect de la dignité :</strong> Cette obligation peut sembler vague en théorie, mais dans la pratique, les travaux qui enfreignent le droit à la dignité d’un salarié sont faciles à reconnaître. De ce fait, l’employeur ne saurait obliger un employé à effectuer un travail jugé dégradant, car la loi prévoit que tout le monde a le droit à la dignité.</p>
<p><strong>Entretenir un environnement sain :</strong> Le mot « sain » prend ici une définition très large qui englobe autant l’aspect psychologique que physique de l’environnement de travail.</p>
<p>Sachez que les droits et obligations mentionnés ci-haut s’appliquent indépendamment du statut de l’employé comme étudiant, comme travailleur occasionnel ou à temps partiel. La loi protège tous les employés, même si aucun contrat de travail écrit n’existe. Il suffit de prouver la relation employeur/employé pour faire naître ces droits!</p>
<h2>Quels sont les motifs de congédiement illégal?</h2>
<p>Il arrive que la relation entre un employeur et son employé rencontre une fin en queue de poisson qui se solde par le congédiement. Si celui-ci est parfois justifié par l’inconduite du salarié visé ou l’insolvabilité de l’entreprise, il arrive également qu’un employé soit mis à pied de façon illégale. La loi stipule donc que les situations suivantes ne sauraient être des causes valides de congédiement.</p>
<p><strong><StaticImage alt="motifs-congediement-illegal-cause-juste-insuffisante-recours-avocat" src="../images/motifs-congediement-illegal-cause-juste-insuffisante-recours-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></strong></p>
<p><strong>Congédiement sans cause juste et suffisant :</strong> Lorsqu’un salarié jouit de 2 ans de service continu pour un employeur, il ne peut pas être congédié sans une cause juste et suffisante. Ces deux critères s’apprécient aux vus et sus de chaque circonstance particulière, mais la violation de ce droit ouvre la porte à un recours en vertu de la Loi sur les normes du travail auprès de la CNESST.</p>
<p><strong>Congédiement d’une femme enceinte :</strong> À moins qu’une femme enceinte commette un acte si répréhensible qu’il justifierait le congédiement de n’importe quel employé, il est interdit de congédier une femme enceinte pour le motif de sa grossesse. L’employeur doit trouver un(e) remplacant(e) temporaire pour pallier à l’absence de la femme en question durant son congé, mais ne peut en aucun cas remplacer celle-ci de façon permanente.</p>
<p><strong>Congédiement déguisé :</strong> Ce type de congédiement prend la forme de manœuvres visant à contourner la loi afin de soi forcer quelqu’un à quitter de son propre gré après avoir ruiné ses conditions de travail ou mettre un employé à pied pour des raisons frivoles. Dans un cas comme dans l’autre, le Tribunal administratif du travail sait reconnaître les cas de congédiement déguisé, n’ayez crainte!</p>
<p><strong>Congédiement suite à l’exercice d’un droit : </strong>La loi met en place des protections afin de garantir le bien-être des travailleurs dans son milieu de travail. Il serait donc illogique qu’un employeur puisse validement congédier un employé pour avoir exercé un de ses droits.</p>
<p><strong>Vous devez entreprendre votre recours rapidement! </strong>Le délai général prévu par la Loi sur les normes du travail pour entreprendre un recours pour un congédiement sans cause juste et suffisante est de 45 jours. Cependant, comme certaines lois régissant certaines industries spécifiques mentionnent d’autres motifs de congédiement illégal, il se peut que le délai applicable soit de 30 jours. Il est donc important d’agir vite et de contracter un avocat le plus rapidement possible!</p>
<h2>Comment entamer le processus d’accréditation syndicale avec un avocat?</h2>
<p>Si vous voulez être certain de lancer un débat enflammé, abordez le sujet de la syndicalisation. C’est un sujet sur lequel les opinions divergent depuis belle lurette et qui sait raviver l’animosité. Laissant la politique de côté, la syndicalisation est un droit que la Charte des droits et libertés protège en vertu du droit d’association. Les principales étapes menant à la mise en place d’un syndicat sont celles-ci :</p>
<p><strong>Le droit d’association. </strong>Tout commence à ce droit fondamental. Un employeur ne peut en aucun cas empêcher un groupe d’employés de former un syndicat, mettre des bâtons dans les roues d’un mouvement de syndicalisation ou encore congédier des employés en pourparlers d’un tel projet.</p>
<p><strong>Identifier l’unité de négociation :</strong> La première étape concrète consiste à identifier une unité de négociation. Il s’agit du groupe de salariés présentant une description de tâche assez similaire pour que la négociation en leur nom collectif se fasse allègrement. Cette unité n’est pas le syndicat lui-même et l’employeur doit accepter la définition de cette unité.</p>
<p><strong>Dépôt de la requête en accréditation :</strong> Le dépôt de la demande est fait au tribunal administratif du travail. Celui-ci doit approuver l’unité de négociation proposée et doit analyser la composante démocratique de la démarche, c’est-à-dire, vérifier si une majorité de salariés veulent se syndiquer.</p>
<p><strong>Négociation et signature de la convention :</strong> Une fois l’accréditation accordée par le Tribunal administratif du travail, il sera temps pour l’association nouvellement accréditée de négocier la mise en place d’une convention collective avec le patronat.</p>
<p><strong> </strong><strong>Les effets du syndicat. </strong>La mise en place d’un syndicat vient ajouter des droits aux salariés dans leur milieu de travail en leur obtenant plus que ce que la loi prévoit d’office. Cependant, le lien « personnel » que l’employeur et l’employé entretenaient au niveau des négociations des conditions de travail est perdu. C’est le syndicat qui parle au nom de la collective.</p>
<h2>Est-il possible de se défaire d’une clause de non-concurrence?</h2>
<p>Les employeurs forcent parfois leurs employés à forcer des clauses de non-concurrence dans des domaines très compétitifs afin de protéger les intérêts de l’entreprise. Ces clauses doivent être limitées en temps, en type de travail et en territoire couvert. Elle vise à empêcher l’employé d’offrir ses services à une autre entreprise. Toutefois ces trois critères peuvent la faire tomber :</p>
<ul>
<li><strong>Territoire proscrit par la clause est trop grand.</strong></li>
<li><strong>Période de non-concurrence trop longue et injustifiée.</strong></li>
<li><strong>Description de tâche trop vaste pour que la clause soit appliquée.</strong></li>
</ul>
<p>Il suffit de prouver qu’un seul des 3 critères prévus dans la clause de non-concurrence est abusif pour que celle-ci soit déclarée invalide par un juge.</p>
<h2>Les protections contre le harcèlement psychologique en milieu de travail</h2>
<p>Une des nouveautés de la loi est l’obligation des employeurs de fournir un milieu de travail dépourvu de « toxicité » et de harcèlement psychologique. Cette obligation consacrée à l’employeur le force à prendre des initiatives pour empêcher qu’un tel harcèlement survienne. La loi prévoit ces principaux critères pour établir un cas de harcèlement psychologique.</p>
<p><StaticImage alt="recours-protections-harcelement-psychologique-travail-employeur-avocat" src="../images/recours-protections-harcelement-psychologique-travail-employeur-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<ul>
<li><strong>Une seule fois suffit pour satisfaire les critères du harcèlement psychologique si la conduite est grave.</strong></li>
<li><strong>L’ignorance de l’employeur n’est pas une défense adéquate; il n’a pas le droit de ne pas savoir.</strong></li>
<li><strong>L’employeur doit prendre des initiatives concrètes pour prévenir le harcèlement. </strong></li>
</ul>
<p>Un employeur qui fait défaut à cette obligation s’expose à des sanctions importantes. Les juges sont très enclins à dissuader des environnements de travail toxiques; il ne faut donc pas hésiter à remédier à la situation.</p>
<h2>Soumissions Avocat vous met en contact avec les meilleurs avocats en droit du travail!</h2>
<p>Quel que soit votre problème en droit du travail, un avocat œuvrant dans le domaine peut assurément vous aider. Que vous soyez liés par une clause de concurrence abusive, ayez été l’objet de harcèlement psychologique ou d’un congédiement illégal, un expert en droit du travail saura vous aider!</p>
<p><strong>La marche à suivre est simple, il suffit de remplir le formulaire pour que nous fassions parvenir votre demande à 3 avocats dans votre région.</strong></p>
<p><strong>N’attendez pas une seconde de plus et contactez dès maintenant pour profiter de notre plateforme gratuite et sans engagement!</strong></p>
    </SeoPage>
)
export default BlogPost44
  